import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { ParallaxProvider } from "react-scroll-parallax";

const About = React.lazy(() => import("pages/About"));
const Merchants = React.lazy(() => import("pages/Merchants"));
const Contactus = React.lazy(() => import("pages/Contactus"));
const FAQs = React.lazy(() => import("pages/FAQs"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <ParallaxProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Merchants />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </Router>
      </ParallaxProvider>
    </React.Suspense>
  );
};
export default ProjectRoutes;
